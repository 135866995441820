.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  padding: 20px;
  align-items: center;
  font-family: 'DM Sans' !important;
  display: flex;
  justify-content: center;
}

/* Navbar.css */
.navbar {
  height: 32px;
  width: 100%;
  padding: 8px 16px;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  color: white;
  font-weight: 700;
  gap: 8px;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
}

.navbar-logo {
  height: 100%; /* Fill the navbar height */
  width: auto; /* Adjust width to mainftain aspect ratio */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 80vh;
  margin-top: 52px;
  font-family: 'DM Sans' !important;
}

.icon-button {
  border: none;
  background-size: 200%;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  background-size: 200% 200%;
  font-family: 'DM Sans';
  font-weight: 500;
  margin-bottom: 16px;
  transition: .3s;
  /* Additional styling as needed */
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
}

.icon-button > img {
  width: 24px;
  height: 24px;
}

.icon-button:hover {
  box-shadow: 0px 4px 24px 0px rgba(3, 139, 0, 0.40);
}

.buttons {
  font-size: 12px;
  font-weight: 600;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: rgb(25, 211, 81);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-family: 'DM Sans' !important;
}

.buttons:active {
  background-color: #00a840;
  transition: .3s;
}

.globalContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: 'DM Sans' !important;
}

.topHeader {
  display: block;
  font-size: 48px;
  line-height: 54px;
  font-weight: 500;
  margin-bottom: 20px;
}

.subHeader {
  font-size: 10px;
  font-weight: 800;
  color:#7a7a7a;
  margin-bottom: 12px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0px 0px 12px 0px;
  font-family: 'DM Sans' !important;
}

.album_inputs {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px;
  border: solid 1px rgb(230, 230, 230);
  font-family: 'DM Sans' !important;
}

.album_inputs::placeholder {
  font-size: 14px;
  color: rgb(186, 186, 186);
  font-family: 'DM Sans' !important;
}

.button-containers {
  display: flex;
  gap: 4px;
  width: 100%;
}

.button-containers button:nth-child(1) {
  width: 70%;
}

.button-containers button:nth-child(2) {
  background-color: white;
  color: black;
  border: solid 1px rgb(25, 211, 81);
  flex-grow: 1;
}

.uri-container {
  display: flex;
  flex-direction: column;
  top: 0;
  gap: 4spx;
  font-size: 12px;
  color: #7a7a7a;
  background-color: rgb(241, 241, 241);
  padding: 16px 16px 16px 16px;
  margin: 20px 0px 20px 0px;
  border-radius: 8px;
}

.headerAndButton {
  position:relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:16px;
}

.headerAndButton span {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 16px;
  color: black;
  flex: 1;
}


.headerAndButton .buttons {
  width: 20%;
}

.trackURIs::selection {
  background-color: #00b344;
  color: white;
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 375px) {
  .main-content {
    width: 100%;
  }

  .headerAndButton {
    flex-direction: column; 
    gap:12px;
  }
  .headerAndButton .buttons, .headerAndButton span {
    width: 100%;
  }

  .headerAndButton .buttons {
    margin-bottom: 16px;
  }
}
